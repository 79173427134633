import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ControllerView from '../views/ControllerView.vue';
import InterestManagerView from '../views/InterestManagerView.vue';
import PoolView from '../views/PoolView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'controller',
    component: ControllerView,
  },
  {
    path: '/interest-manager',
    name: 'interest-manager',
    component: InterestManagerView,
  },
  {
    path: '/pool',
    name: 'pool',
    component: PoolView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
