<template>
  <section>
    <div class="columns">
      <div class="column center">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h2>Controller</h2>
              <b-field grouped>
                <b-input v-model="address" placeholder="Address" expanded rounded></b-input>
              </b-field>

              <b-field>
                <b-button type="is-info" label="Load" @click="load" expanded />
              </b-field>

              <div class="buttons">
                <b-button class="half-button" type="is-info" label="Approve"
                          @click="transaction(0x7b4b42e6)" expanded />
                <b-button class="half-button" type="is-info" label="Disapprove"
                          @click="transaction(0xe8a0abfe)" expanded />
                <b-button class="half-button" type="is-info" label="Halt"
                          @click="transaction(0x139a1b4e)" expanded />
                <b-button class="half-button" type="is-info" label="Unhalt"
                          @click="transaction(0x7247e7a5)" expanded />
                <b-button class="half-button" type="is-info" label="Recover Stake"
                          @click="transaction(0xeb373a05)" expanded />
                <b-button class="half-button" type="is-info" label="Update Validator Hash"
                          @click="transaction(0xf0fd2250)" expanded />
                <b-button type="is-info" label="Return Unused Loan"
                          @click="transaction(0xed7378a6)" expanded />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="data !== null" class="columns">
      <div class="column center">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <table class="table stats">
                <tbody>
                <tr>
                  <td>State</td>
                  <td>{{ data.state }}</td>
                </tr>
                <tr>
                  <td>Halted</td>
                  <td>{{ data.halted }}</td>
                </tr>
                <tr>
                  <td>Approved</td>
                  <td>{{ data.approved }}</td>
                </tr>
                <tr>
                  <td>Stake Amount Sent</td>
                  <td>{{ (data.stakeAmountSent / 1e9).toFixed(9) }}</td>
                </tr>
                <tr>
                  <td>Stake At</td>
                  <td>{{ data.stakeAt }}</td>
                </tr>
                <tr>
                  <td>Saved Validator Set Hash</td>
                  <td>
                    <div class="validator-hash">
                      <a href="#/" @click.prevent="copySavedValidatorSetHash">
                        {{ data.savedValidatorSetHash }}
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Validator Set Changes Count</td>
                  <td>{{ data.validatorSetChangesCount }}</td>
                </tr>
                <tr>
                  <td>Validator Set Change Time</td>
                  <td>{{ data.validatorSetChangeTime }}</td>
                </tr>
                <tr>
                  <td>Stake Held For</td>
                  <td>{{ data.stakeHeldFor }}</td>
                </tr>
                <tr>
                  <td>Borrowed Amount</td>
                  <td>{{ (data.borrowedAmount / 1e9).toFixed(9) }}</td>
                </tr>
                <tr>
                  <td>Borrowing Time</td>
                  <td>{{ data.borrowingTime }}</td>
                </tr>
                <tr>
                  <td>Validator</td>
                  <td v-if="data.validator"><a :href="'https://testnet.ton.cx/address/' + data.validator" target="_blank">{{ getValidator }}</a></td>
                  <td v-else>empty</td>
                </tr>
                <tr>
                  <td>Pool</td>
                  <td v-if="data.pool"><a :href="'https://testnet.ton.cx/address/' + data.pool" target="_blank">{{ getPool }}</a></td>
                  <td v-else>empty</td>
                </tr>
                <tr>
                  <td>Sudoer</td>
                  <td v-if="data.sudoer"><a :href="'https://testnet.ton.cx/address/' + data.sudoer" target="_blank">{{ getSudoer }}</a></td>
                  <td v-else>empty</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { beginCell, address } from 'ton-core';
import { CONTROLLER_AMOUNT } from '@/config';
import { getControllerData, getPrettifyAddress } from '@/utils';

export default {
  data() {
    return {
      address: '',
      data: null,
    };
  },
  computed: {
    getValidator() {
      return getPrettifyAddress(this.data.validator);
    },
    getPool() {
      return getPrettifyAddress(this.data.pool);
    },
    getSudoer() {
      return getPrettifyAddress(this.data.sudoer);
    },
  },
  methods: {
    copySavedValidatorSetHash() {
      navigator.clipboard.writeText(this.data.savedValidatorSetHash);
      this.$buefy.toast.open({ message: 'Copied to the clipboard successfully', type: 'is-success' });
    },
    async load() {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      try {
        address(this.address);
      } catch (error) {
        this.$buefy.toast.open('Incorrect address entered');
        return;
      }

      // load
      this.data = await getControllerData(window.client4, address(this.address));
    },

    async transaction(op) {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      try {
        address(this.address);
      } catch (error) {
        this.$buefy.toast.open('Incorrect address entered');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(op, 32) // op
        .storeUint(1, 64) // query id
        .endCell();
      const payload = cell.toBoc();
      console.log(payload.toString('base64'));

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: this.address,
              amount: CONTROLLER_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 500px;
  box-shadow: none;
}

.card p {
  text-align: justify;
}

.buttons {
  justify-content: space-between;
}

.half-button {
  width: calc(50% - 5px);
}

.stats td:first-child {
  text-align: left;
}

.stats td:last-child {
  text-align: right;
}

.validator-hash {
  width: 200px;
  white-space: initial;
  word-wrap: break-word;
}
</style>
