import { TonClient4, Address } from 'ton';

export const getAccountState = async (client: TonClient4, accountAddress: Address) => {
  const { last: { seqno } } = await client.getLastBlock();
  const { account: { balance: { coins } } } = await client.getAccount(seqno, accountAddress);

  return {
    address: accountAddress.toString({
      urlSafe: true,
      bounceable: true,
      testOnly: true,
    }),
    tonBalance: Number(coins),
    tunaBalance: 0,
  };
};

export const getPrettifyAddress = (nonFormatAddress: string) => `${nonFormatAddress.slice(0, 6)}...${nonFormatAddress.slice(-6)}`;

export const getControllerData = async (client: TonClient4, controllerAddress: Address) => {
  const { last: { seqno } } = await client.getLastBlock();
  const { reader } = await client.runMethod(seqno, controllerAddress, 'get_validator_controller_data', []);
  const stack = reader;

  const state = Number(stack.readBigNumber());
  const halted = Number(stack.readBigNumberOpt());
  const approved = Number(stack.readBigNumberOpt());

  const stakeAmountSent = Number(stack.readBigNumber());
  const stakeAt = Number(stack.readBigNumber());
  const savedValidatorSetHash = stack.readBigNumber().toString(16);
  const validatorSetChangesCount = Number(stack.readBigNumber());
  const validatorSetChangeTime = Number(stack.readBigNumber());
  const stakeHeldFor = Number(stack.readBigNumber());

  const borrowedAmount = Number(stack.readBigNumber());
  const borrowingTime = Number(stack.readBigNumber());

  const validator = stack.readAddressOpt();
  const pool = stack.readAddressOpt();
  const sudoer = stack.readAddressOpt();

  return {
    state,
    halted,
    approved,
    stakeAmountSent,
    stakeAt,
    savedValidatorSetHash,
    validatorSetChangesCount,
    validatorSetChangeTime,
    stakeHeldFor,
    borrowedAmount,
    borrowingTime,
    validator: validator ? validator.toString({
      urlSafe: true,
      bounceable: true,
      testOnly: true,
    }) : null,
    pool: pool ? pool.toString({
      urlSafe: true,
      bounceable: true,
      testOnly: true,
    }) : null,
    sudoer: sudoer ? sudoer.toString({
      urlSafe: true,
      bounceable: true,
      testOnly: true,
    }) : null,
  };
};

export const sendFile = (payload: Buffer, filename: string) => {
  const blob = new Blob([payload]);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};
