<template>
  <div class="columns">
    <div class="column center">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h2>Pool</h2>

            <b-field>
              <b-input v-model="amount" placeholder="Amount" expanded rounded></b-input>
            </b-field>
            <b-field>
              <b-button type="is-info" label="Donate" @click="donate" expanded />
            </b-field>

            <hr>

            <b-field>
              <b-input v-model="percent" placeholder="Percent" expanded rounded></b-input>
            </b-field>
            <div class="buttons">
              <b-button class="half-button" type="is-info" label="Set Governance Fee"
                        @click="setGovernanceFee(false)" />
              <b-button class="half-button" type="is-info" label="Generate Boc"
                        @click="setGovernanceFee(true)" />
            </div>
            <p v-if="governanceFeeBoc.length > 0">
              <code>{{ governanceFeeBoc }}</code>
            </p>

            <hr>

            <b-field grouped class="center-column">
              <b-switch v-model="optimisticDepositWithdrawals" type="is-success">
                <small>Optimistic Deposit Withdrawals</small>
              </b-switch>
              <b-switch v-model="depositsOpen" type="is-success">
                <small>Deposits Open</small>
              </b-switch>
            </b-field>
            <div class="buttons">
              <b-button class="half-button" type="is-info" label="Set Deposit Settings"
                        @click="setDepositSettings(false)" />
              <b-button class="half-button" type="is-info" label="Generate Boc"
                        @click="setDepositSettings(true)" />
            </div>
            <p v-if="depositSettingsBoc.length > 0">
              <code>{{ depositSettingsBoc }}</code>
            </p>

            <hr>

            <b-field grouped>
              <b-button type="is-info" label="Touch" expanded @click="touch" />
            </b-field>

            <hr>

            <b-field>
              <b-input v-model="sudoerAddress" placeholder="Address" expanded rounded></b-input>
            </b-field>
            <div class="buttons">
              <b-button class="half-button" type="is-info" label="Set Sudoer"
                        @click="setSudoer(false)" />
              <b-button class="half-button" type="is-info" label="Generate Boc"
                        @click="setSudoer(true)" />
            </div>
            <p v-if="sudoerAddressBoc.length > 0">
              <code>{{ sudoerAddressBoc }}</code>
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { beginCell, address } from 'ton-core';
import {
  TRANSACTION_AMOUNT,
  INTEREST_AMOUNT,
  SET_DEPOSIT_SETTINGS_AMOUNT,
  SET_SUDOER_AMOUNT,
} from '@/config';

export default {
  data() {
    return {
      amount: '',
      percent: '',
      optimisticDepositWithdrawals: false,
      depositsOpen: false,
      governanceFeeBoc: '',
      depositSettingsBoc: '',
      sudoerAddress: '',
      sudoerAddressBoc: '',
    };
  },
  methods: {
    async donate() {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      const amount = this.amount * 1e9;
      if (Number.isNaN(amount) || amount <= 0) {
        this.$buefy.toast.open('Incorrect amount entered');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(0x73affe21, 32) // op = pool::donate
        .storeUint(1, 64) // query id
        .endCell();
      const payload = cell.toBoc();
      console.log(payload.toString('base64'));

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: window.POOL_ADDRESS,
              amount: amount + TRANSACTION_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },

    async touch() {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(0x4bc7c2df, 32) // op = pool::touch
        .storeUint(1, 64) // query id
        .endCell();
      const payload = cell.toBoc();
      console.log(payload.toString('base64'));

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: window.POOL_ADDRESS,
              amount: TRANSACTION_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },

    async setGovernanceFee(boc = false) {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      const percent = parseFloat(this.percent);
      if (Number.isNaN(percent) || percent < 0 || percent > 100) {
        this.$buefy.toast.open('Incorrect percent entered');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(0x2aaa96a0, 32) // op = governor::set_governance_fee
        .storeUint(1, 64) // query id
        .storeUint(Math.ceil((16777216 * percent) / 100), 24) // interest rate
        .endCell();
      const payload = cell.toBoc();
      console.log(payload.toString('base64'));

      // send boc
      if (boc) {
        this.governanceFeeBoc = payload.toString('base64');
        await navigator.clipboard.writeText(this.governanceFeeBoc);
        this.$buefy.toast.open({ message: 'Copied to the clipboard successfully', type: 'is-success' });
        return;
      }

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: window.POOL_ADDRESS,
              amount: INTEREST_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },

    async setDepositSettings(boc = false) {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(0x9bf5561c, 32) // op = governor::set_deposit_settings
        .storeUint(1, 64) // query id
        .storeUint(Number(this.optimisticDepositWithdrawals), 1)
        .storeUint(Number(this.depositsOpen), 1)
        .endCell();
      const payload = cell.toBoc();
      console.log(payload.toString('base64'));

      // send boc
      if (boc) {
        this.depositSettingsBoc = payload.toString('base64');
        await navigator.clipboard.writeText(this.depositSettingsBoc);
        this.$buefy.toast.open({ message: 'Copied to the clipboard successfully', type: 'is-success' });
        return;
      }

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: window.POOL_ADDRESS,
              amount: SET_DEPOSIT_SETTINGS_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },

    async setSudoer(boc = false) {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      try {
        address(this.sudoerAddress);
      } catch (error) {
        this.$buefy.toast.open('Incorrect address entered');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(0x79e7c016, 32) // op = governor::set_sudoer = 0x79e7c016;
        .storeUint(1, 64) // query id
        .storeAddress(address(this.sudoerAddress))
        .endCell();
      const payload = cell.toBoc();
      console.log(payload.toString('base64'));

      // send boc
      if (boc) {
        this.sudoerAddressBoc = payload.toString('base64');
        await navigator.clipboard.writeText(this.sudoerAddressBoc);
        this.$buefy.toast.open({ message: 'Copied to the clipboard successfully', type: 'is-success' });
        return;
      }

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: window.POOL_ADDRESS,
              amount: SET_SUDOER_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 500px;
  box-shadow: none;
}

.card p {
  text-align: justify;
}

.buttons {
  justify-content: space-between;
}

.half-button {
  width: calc(50% - 5px);
}

.year-interest {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  left: 20px;
}
</style>
