import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    connected: false,
    account: {
      address: '',
      tonBalance: 0,
      tunaBalance: 0,
    },
  },
  getters: {
    tonBalance(state) {
      return `${state.account.tonBalance / 1e9}`;
    },
  },
  mutations: {
    UPDATE_CONNECT_STATE(state, payload) {
      state.connected = payload;
    },
    UPDATE_ACCOUNT_STATE(state, payload) {
      state.account = payload;
    },
  },
  actions: {
    updateConnectState(context, payload) {
      context.commit('UPDATE_CONNECT_STATE', payload);
    },
    updateAccountState(context, payload) {
      context.commit('UPDATE_ACCOUNT_STATE', payload);
    },
    resetAccountState(context) {
      context.commit('UPDATE_ACCOUNT_STATE', {
        address: '',
        tonBalance: 0,
        tunaBalance: 0,
      });
    },
  },
  modules: {
  },
});
