<template>
  <div id="app">
    <div class="columns testnet">
      <div class="column">
        <div class="testnet-message">TESTNET VERSION</div>
      </div>
    </div>
    <div class="columns header">
      <div class="column center is-3">
        <figure class="image navbar-item">
          <img height="56" src="./assets/logo.svg" alt="TON Logo" />
        </figure>
      </div>
      <nav class="column center navbar">
        <router-link class="navbar-item" to="/">
          <p class="has-text-grey-dark">Controller</p>
        </router-link>
        <router-link class="navbar-item" to="/interest-manager">
          <p class="has-text-grey-dark">Interest Manager</p>
        </router-link>
        <router-link class="navbar-item" to="/pool">
          <p class="has-text-grey-dark">Pool</p>
        </router-link>
      </nav>
      <div class="column center is-3">
        <a v-if="this.$store.state.connected" target="_blank"
           :href="'https://testnet.ton.cx/address/' + this.$store.state.account.address"
           class="balance">
          {{ $store.getters.tonBalance }} TON
        </a>
        <div class="navbar-item" id="TONConnect"></div>
      </div>
    </div>
    <main>
      <section class="container is-max-widescreen">
        <router-view/>
      </section>
    </main>
    <footer class="footer has-background-white">
      <div class="content has-text-centered">
        <p class="copyright">
          TON Foundation &copy; {{ new Date().getFullYear() }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { TonClient4 } from 'ton';
import { address } from 'ton-core';
import { getAccountState } from '@/utils';
import { API_ENDPOINT, MANIFEST_ENDPOINT, POOL_ADDRESS } from '@/config';

export default {
  data() {
    return {
    };
  },
  async mounted() {
    // addresses
    window.POOL_ADDRESS = POOL_ADDRESS;
    if ('pool' in this.$route.query) {
      try {
        address(this.$route.query.pool);
        window.POOL_ADDRESS = this.$route.query.pool;
      } catch (error) {
        //
      }
    }

    // libs
    window.connector = new window.TonConnectUI({
      manifestUrl: MANIFEST_ENDPOINT,
      buttonRootId: 'TONConnect',
    });
    window.client4 = new TonClient4({ endpoint: API_ENDPOINT });

    // update account state
    const accountStateInterval = async () => {
      if (!window.connector.connected) {
        await this.$store.dispatch('resetAccountState', null);
        return;
      }

      const accountState = await getAccountState(
        window.client4,
        address(window.connector.account.address),
      );
      await this.$store.dispatch('updateAccountState', accountState);
    };

    window.connector.onStatusChange(async () => {
      await this.$store.dispatch('updateConnectState', window.connector.connected);
      await accountStateInterval();
    });
    setInterval(accountStateInterval, 5000);
  },
};
</script>

<style>
.center, .center-column, .top, .right, .bottom, .left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-column {
  flex-direction: column;
}

.top {
  align-items: flex-start;
}

.right {
  justify-content: flex-end;
}

.bottom {
  align-items: flex-end;
}

.left {
  justify-content: flex-start;
}

.single-spaced, .single-spaced * {
  line-height: 1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  min-height: 100px;
}

.navbar {
  padding: 30px;
}

.navbar a {
  font-weight: bold;
  color: #2c3e50;
}

.navbar a.router-link-exact-active p {
  color: #167df0 !important;
}

main {
  padding: 40px 0;
  background: #E9EEF3;
}

.balance {
  font-weight: 700;
}

.copyright {
  font-size: 12px;
  opacity: 0.4;
}

.testnet {
  background: red;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  height: 40px;
}

.testnet-message {
  position:relative;
  top: 3px;
}
</style>
