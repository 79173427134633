<template>
  <section>
    <div class="columns">
      <div class="column center">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h2>Interest Manager</h2>
              <b-field grouped>
                <b-input v-model="interest"
                  placeholder="Interest" expanded rounded></b-input>
              </b-field>
              <b-field class="left year-interest">
                  year interest: {{ yearInterest }}
              </b-field>
              <b-field>
                <b-button type="is-info" label="Set Interest" @click="setInterest" expanded />
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { beginCell } from 'ton-core';
import { INTEREST_AMOUNT } from '@/config';

export default {
  data() {
    return {
      interest: '',
    };
  },
  computed: {
    yearInterest() {
      const roundPeriod = 7200;
      const percentPerRound = parseFloat(this.interest);
      if (Number.isNaN(percentPerRound) || percentPerRound < 0 || percentPerRound > 100) {
        return '';
      }

      // eslint-disable-next-line no-mixed-operators,max-len
      const yearInterest = (((1 + percentPerRound / 100) ** (365 * 24 * 3600 / roundPeriod)) - 1) * 100;
      return `${yearInterest}%`;
    },
  },
  methods: {
    async setInterest() {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      const interest = parseFloat(this.interest);
      if (Number.isNaN(interest) || interest < 0 || interest > 100) {
        this.$buefy.toast.open('Incorrect interest entered');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(0xc9f04485, 32) // op = interest_manager::set_interest
        .storeUint(1, 64) // query id
        .storeUint(Math.ceil((16777216 * interest) / 100), 24) // interest rate
        .endCell();
      const payload = cell.toBoc();
      console.log(payload.toString('base64'));

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: window.POOL_ADDRESS,
              amount: INTEREST_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 500px;
  box-shadow: none;
}

.card p {
  text-align: justify;
}

.year-interest {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  left: 20px;
}
</style>
