export const API_ENDPOINT = 'https://testnet-v4.tonhubapi.com';
export const MANIFEST_ENDPOINT = 'https://admin.teststaking.xyz/tonconnect-manifest.json';

export const POOL_ADDRESS = 'EQCu_j-5niSEIN_R3qJMWvcjKSdpBJOFz1sJE9JXt549GL42';

export const TRANSACTION_AMOUNT = 1e9;
export const INTEREST_AMOUNT = 0.3 * 1e9;
export const CONTROLLER_AMOUNT = 0.3 * 1e9;
export const SET_DEPOSIT_SETTINGS_AMOUNT = 0.3 * 1e9;
export const SET_SUDOER_AMOUNT = 0.3 * 1e9;
